import React, { useContext } from 'react'
import { useState } from 'react';
import styled from 'styled-components';
import Loading from '../../components/common/Loading';
import Layout from '../../components/layout';
import Input from '../../components/layout/Input';
import { AppContext } from '../../contexts/AppContext';
import axios from 'axios';
import { navigate } from 'gatsby-link';
import { SET_USER } from '../../reducers/AppReducer';
import InputButton from '../../components/common/InputButton';
import SEO from '../../components/seo';
import { Link } from 'gatsby';

const Container = styled.div`
    position: relative;
    top: 20vh;
`

const Form = styled.form`
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    max-width: 600px;
    justify-content: space-between;
    align-items: center;
`

const InputTitle = styled.div`
    font-size: 12pt;
    color: ${props => props.theme.default.colors.mediumGrey};
    margin-bottom: 7px;
`

const InputGroup = styled.div`
    margin-bottom: 30px;
`

const Inputs = styled.div`
    margin-bottom: 20px;
`

const Title = styled.h2`
    color: ${props => props.theme.default.colors.primary};
    margin-bottom: 50px;
`

const Error = styled.div`
    font-weight: 500;
    margin-left: 10px;
    padding-bottom: 20px;
    display: block;
    color: rgb(255, 85, 85);
    font-size: 8pt;
`

const ResetPasswordLink = styled.div`
    padding: 20px;
`



export default function Login() {

    const { dispatch } = useContext(AppContext);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const apiUrl = process.env.GATSBY_API_ENDPOINT;
    // const apiUrl = 'http://localhost:5000';
    // const apiUrl = "https://api-xeu74yuiwq-ue.a.run.app"


    const submit = async e => {
        e.preventDefault();
        if (username && password) {
            setLoading(true)
            setError("")
            try {
                const response = await axios.post(`${apiUrl}/auth/login`, {
                    email: username,
                    password: password
                })
                if (response.data.accessToken && response.data.refreshToken) {
                    navigate("/account")
                    dispatch({ type: SET_USER, payload: response.data })
                } else {
                    throw new Error()
                }
            } catch (e) {
                console.error("Login failed")
                setError("Algo falhou ao entrar, se continuar a acontecer contacte-nos em geral@evazenergy.pt")
            }
            setLoading(false)
        } else {
            setError("Por favor preencha todos os campos")
        }

    }

    return (
        <Layout>
            <SEO title={'Área de Cliente'.toUpperCase()} />

            <Container>
                <Form>
                    <Title>{'Área de Cliente'.toUpperCase()}</Title>
                    <Inputs>
                        <InputGroup>
                            <InputTitle>Email</InputTitle>
                            <Input required type={"email"} title={"email"} value={username} onChange={
                                e => setUsername(e.target.value)
                            }></Input>
                        </InputGroup>

                        <InputGroup>
                            <InputTitle>Password</InputTitle>
                            <Input required type={"password"} title={"pass"} value={password} onChange={e => setPassword(e.target.value)
                            }></Input>
                        </InputGroup>
                    </Inputs>
                    {error ? <Error>{error}</Error> : null}
                    {loading ? <Loading /> : <InputButton type={"submit"} onClick={e => submit(e)} value="Entrar"></InputButton>}
                    <ResetPasswordLink>
                        <Link to="./reset">
                            <small>Recuperar palavra-passe</small>
                        </Link>
                    </ResetPasswordLink>
                </Form>
            </Container>
        </Layout>
    )
}
