import React from 'react'
import styled from 'styled-components'


const _Input = styled.input`
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border-width: 0;
    min-width: 15rem;
    max-width: 20rem;
    width: -webkit-fill-available;;
    padding: 12px 20px 12px 20px;
    border-radius: 5px;
    background: #ffffff;
    -webkit-box-shadow: 6px 11px 0 #e3e3e3, 
                        -6px 11px 0 #ffffff;
    box-shadow:  6px 6px 11px 0 #e3e3e3, 
                -6px -6px 11px 0 #ffffff;
    -webkit-appearance: none;
`

const Input = ({onChange, value, name, required, onClick, type}) => {
    return (
        <_Input autoComplete={"on"} name={name} required={required} onChange={onChange} value={value} onClick={onClick} type={type}/>
    )
        
}

export default Input