import React from 'react'
import styled from 'styled-components'
import { useContext } from 'react'
import { AppContext } from '../../contexts/AppContext'

const _Button = styled.input`
        font-family: ${props => props.theme.default.fonts.family};
        padding: 0.6rem !important;
        width: ${props => props.width ?? '8rem !important'};
        height: 1.2rem !important;
        line-height: 0 !important;
        box-sizing: unset !important;
        border-width: 0;
        background-color: ${props => props.primary ? props.theme.default.colors.primary : 'transparent'} !important;
        color: ${props => props.primary ? props.theme.default.colors.secondary : props.theme.default.colors.mediumGrey} !important;
        border-radius: 5px;
        text-transform: uppercase;
        font-weight: 700;
        cursor: pointer;
        -webkit-box-shadow: ${props => props.primary ? `6px 11px 0 #e3e3e3, -6px -6px 11px 0 #ffffff !important` : `none`};
        -moz-box-shadow: ${props => props.primary ? `6px 6px 11px 0 #e3e3e3, -6px -6px 11px 0 #ffffff !important` : `none`};
        box-shadow:  ${props => props.primary ? `6px 6px 11px #e3e3e3, -6px -6px 11px #ffffff !important` : `none`};
        font-weight: 600 !important;
    `



export default function InputButton({ primary, label, onChange, value, name, required, onClick, type, width }) {
    const { sendEvent } = useContext(AppContext);
    
    const onButtonClick = (_onClick, _label, _e) => {
        sendEvent("On Click", "Default Button", _label)
        if(_onClick) _onClick(_e)
    }

    return (
        <_Button primary={primary} onClick={(e) => onButtonClick(onClick, label, e)} width={width} name={name} required={required} onChange={onChange} value={value} type={type} />
    )
}


InputButton.defaultProps = {
    primary: "true",
    label: "Clique Aqui"
};

